import React, { useState } from 'react'
import "./signupreferal.scss";
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { axiosApi } from '../../_helper/api_helper';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';
const SignUpReferalNew = () => {
    const [user, setUser] = useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showconfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickconfirmShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();
    const handleMouseConfirmDownPassword = (event) => event.preventDefault();
    const handleMouseUpPassword = (event) => event.preventDefault();
    const handleMouseConfrimUpPassword = (event) => event.preventDefault();
    const [confirmTouched, setConfirmTouched] = useState(false);
    const [loading, setLoading] = useState(false)

    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",

    })
    const handleGoogleLoginSuccess = (response) => {
        console.log("checkResponse", response)
        // You can now store the user's info in your state or context
    };

    const handleGoogleLoginFailure = (error) => {
        console.log("Google Login Failed", error);
    };

    
    const handlereferalChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

        // Compare password and confirmation password only after user types in confirm password
        if (name === 'password_confirmation') {
            setConfirmTouched(true);
            setPasswordMatch(formValues.password === value);
        }
    };

    const handleRegistrationSubmit = async (e) => {
        e.preventDefault();
        const toastOptions = {
            position: 'top-left',
            autoClose: 10000,
            hideProgressBar: false,
        };
        setLoading(true);
        if (!formValues?.name) {
            toast.error('Name is required', toastOptions);
            return;
        }
        if (!formValues?.email) {
            toast.error('Email is required', toastOptions);
            return;
        }
        if (!formValues?.password) {
            toast.error('Password is required', toastOptions);
            return;
        }
        if (!formValues?.password_confirmation) {
            toast.error('Confirm password is required', toastOptions);
            return;
        }
        const payload = {
            name: formValues?.name,
            email: formValues?.email,
            password: formValues?.password,
            password_confirmation: formValues?.password_confirmation,
        }
        try {
            const response = await axiosApi.post('/register', payload, );
            setFormValues({
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
            });
            toast.success("We have send the verify link on your register email. Kindly login and verify", {
                position: 'top-center',
            });
            console.log("User registered successfully", response.data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error?.response?.data?.email[0], {
                    position: 'top-center',
                });
                console.log("errormsgshow", error.response)
            } else if (error.response) {
                // Handle other server-side errors
                alert(`An error occurred: ${error.response.statusText || "Unknown error"}`);
            } else {
                // Handle network or client-side errors
                alert("A network error occurred. Please try again later.");
            }
        }finally {
            setLoading(false)
          }
    }
    return (
        <>
          {loading && LoadingPannel()}
         <div className="refer-to-signup-container">
            <section className="refer-to-signup-container">
                <div className="refer-to-signup-flex1">
                    <img src="/image/hiremadeeasy.png" alt="Recruiter Login Banner" />
                </div>
                <div className="refer-to-signup-flex2">
                    <div className="refer-to-signup-flex2-container">
                        <div className="rerfer-earn-logo">
                            <img src="/image/nbflogo.png" alt="NBF Logo" />
                        </div>
                        <h4>
                            Create Your NBF Account
                        </h4>
                        {/* <div>
                            <GoogleLogin
                                className="google-login"
                                onSuccess={handleGoogleLoginSuccess}
                                onError={handleGoogleLoginFailure}
                                useOneTap
                                style={{ width: '100%' }}
                            />
                        </div> */}

                        <form className="">

                            {!user && (
                                <>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={
                                            <>
                                                Name <span style={{ color: 'red' }}>*</span>
                                            </>
                                        }
                                        multiline
                                        name='name'
                                        className='refer-earn-input'
                                        value={formValues?.name}
                                        onChange={handlereferalChange}
                                    />
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={
                                            <>
                                                Email <span style={{ color: 'red' }}>*</span>
                                            </>
                                        }
                                        multiline
                                        value={formValues?.email}
                                        name='email'

                                        className='refer-earn-input'
                                        onChange={handlereferalChange}
                                    />

                                    <FormControl className='refer-earn-input' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Enter Password  <span style={{ color: 'red' }}>*</span></InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formValues?.password}
                                            onChange={handlereferalChange}
                                            name='password'

                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onMouseUp={handleMouseUpPassword}

                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                            label="Enter Password"
                                        />
                                        <FormHelperText id="outlined-weight-helper-text">Minimum 15 letters with digits & alphabets</FormHelperText>
                                    </FormControl>
                                    <FormControl className='refer-earn-input' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password  <span style={{ color: 'red' }}>*</span></InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showconfirmPassword ? 'text' : 'password'}
                                            value={formValues?.password_confirmation}
                                            // onChange={handlereferalConfirmChange}
                                            onChange={handlereferalChange}
                                            name='password_confirmation'

                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickconfirmShowPassword}
                                                        onMouseDown={handleMouseConfirmDownPassword}
                                                        onMouseUp={handleMouseConfrimUpPassword}

                                                        edge="end"

                                                    >
                                                        {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                            label="Confirm Password"
                                        />
                                        {!passwordMatch && confirmTouched && (
                                            <FormHelperText error>Passwords do not match</FormHelperText>
                                        )}
                                    </FormControl>
                                    <div>
                                        <p>Already a member ? <span><Link to='/refer-login'>Log in</Link></span></p>

                                    </div>
                                    <div className="refer-earn-button">
                                        <button onClick={handleRegistrationSubmit} type="submit">Submit</button>
                                    </div>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </section>
            <div></div>
            <ToastContainer />

        </div>
        </>
       
    )
}

export default SignUpReferalNew
